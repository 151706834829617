<template>
  <div class="feedback-box">
      <nav-index :title="'意见反馈'"/>
      <div class="title">问题/建议描述:</div>
      <div class="desc">请具体描述问题发生的过程，或描述您的具体建议，越详细越好~</div>
      <div class="row">
        <textarea ref="textarea" wrap="soft" placeholder="请输入反馈内容..." class="textarea" v-model="feedbackText"
        @input="adjustTextareaHeight" maxlength="1000" rows="5"></textarea>
      </div>
      <div class="tips">
        <div>本次问题反馈用于系统功能问题收集(无人工回复)，若您的问题着急处理，请联系客服~</div>
        <div>感谢您的反馈，祝您生活愉快！</div>
      </div>
      <div class="mfz-button" :style="{ backgroundImage : feedbackText.length > 0 ? 'linear-gradient(60deg, #29323c 0%, #485563 100%);': 'linear-gradient(60deg, rgba(41, 50, 60,0.5) 0%, rgba(72, 85, 99, 0.5) 100%);'}" @click="submit">
        提交
      </div>
  </div>
</template>

<script>
import NavIndex from '../../components/nav/index.vue'
import { addFeedback } from '@/api/api'
import { Toast } from 'vant'

export default {
name: 'Feedback',
components: {
  NavIndex,
},
created(){
  this.initData()
},
mounted(){
},
data(){
    return {
      feedbackText:'',
      isLogin: localStorage.getItem('token') ? true : false,
    }
},
methods:{
  initData(){
  },
  adjustTextareaHeight(){
    this.$refs.textarea.style.height = 'auto';
    this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 5 + 'px';
  },
  submit(){
    let uid = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
    if(this.feedbackText.length > 0){
      addFeedback({ text: this.feedbackText, uid: uid }).then(()=>{
        Toast('感谢您的反馈~')
        this.feedbackText = ''
        this.$nextTick(()=>{
          this.adjustTextareaHeight()
        })
      })
    }
  }
},
props:{

}
}
</script>

<style scoped lang="scss">
.feedback-box {
  padding-top: 55px;
  height: 100vh;
  box-sizing: border-box;
  .row {
    margin: 15px;
    .textarea{
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        font-family: PingFangSC-Semibold, PingFang SC;
        line-height: 20px;
        outline: 0;
        word-wrap: break-word;
        overflow-x: hidden;
        overflow-y: auto;
        background: #fefefe;
        border-radius: 10px;
        font-weight: 100;
        font-size: 14px;
        color: #000;
    }
    textarea{
      border-radius: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      padding: 10px;
      outline:none;
      resize:none;
    }
  }
  .title {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    text-align: left;
    padding: 10px 25px 0;
  }
  .desc {
    text-align:left;
    font-size: 14px;
    padding: 10px 25px 0;
  }
  .tips {
    text-align:left;
    font-size: 14px;
    padding: 0px 25px 15px;
  }
}
</style>
